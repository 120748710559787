import toastr from 'toastr';
import 'toastr/build/toastr.css'
const axios = require('axios');
let Toastr = toastr;

export const BACKEND_URL = "https://helpdesc.neytin.com";
// export const BACKEND_URL = "http://127.0.0.1:3333";

let serialize = function(obj, prefix) {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

export function flash_messages(text, type='s'){
    if(type === 'w'){
        Toastr.warning(text)
    }
    if(type === 's'){
        Toastr.success(text)
    }
}

export function compile_errors_request(response){
    if(response && response.status){
        if(response.status === 500){
            Toastr.warning("Что-то пошло не так")
        }else if(response.status === 400){
            response.data.errors.map(x => {
                Toastr.warning(x)
            })
        }else if(response.status === 401){
            localStorage.clear();
            window.location.href = '/login'
        }else if(response.data.message){
            Toastr.warning(response.data.message)
        }
    }else{
        Toastr.warning(response)
    }
}

export function api_request_promise(type='get', method, params = {}) {
    return new Promise((resolve, reject) => {
        api_request(type, method, params, resolve, reject);
    })
}

export function api_request_file_promise(method, body) {
    return new Promise((resolve, reject) => {
        api_request_file(method, body, resolve, reject);
    })
}

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
}

export function api_request_file(method, data, callback_success = false, callback_error = false){

    let auth_token = getCookie("auth_token");
    if(auth_token === null){
        auth_token = localStorage.getItem("auth_token");
    }else{
        localStorage.setItem("auth_token", auth_token)
    }

    axios({
        method: 'POST',
        url: BACKEND_URL+'/api/'+method + "?",
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + auth_token,
        }
    }).then(resp => {
        let data = resp.data;
        if(callback_success){
            callback_success(data)
        }
    }).catch(err => {
        console.log(err)
        compile_errors_request(err.response);
        if(callback_error){
            callback_error(err.response)
        }
    })
}


export function api_request(type='get', method, params = {}, callback_success = false, callback_error = false){
    let c_params = "";

    if(type === 'get'){
        c_params = serialize(params)
    }

    let auth_token = getCookie("auth_token");
    if(auth_token === null){
        auth_token = localStorage.getItem("auth_token");
    }else{
        localStorage.setItem("auth_token", auth_token)
    }

    axios({
        method: type,
        url: BACKEND_URL+'/api/'+method + "?" + c_params,
        data: params,
        headers: {
            "Authorization": "Bearer " + auth_token,
        }
    }).then(resp => {
        let data = resp.data;
        if(callback_success){
            callback_success(data)
        }
    }).catch(err => {
        console.log(err)
        compile_errors_request(err.response);
        if(callback_error){
            callback_error(err.response)
        }
    })
}

export function getRandomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export  function getRandomArbitrary(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomDate(date1, date2){
    function randomValueBetween(min, max) {
        return Math.random() * (max - min) + min;
    }
    var date1 = date1 || '01-01-1970'
    var date2 = date2 || new Date().toLocaleDateString()
    date1 = new Date(date1).getTime()
    date2 = new Date(date2).getTime()
    if( date1>date2){
        return new Date(randomValueBetween(date2,date1)).getTime()
    } else{
        return new Date(randomValueBetween(date1, date2)).getTime()

    }
}

export function intersect (arr1, arr2) {
    return arr1.filter(function(n) {
        return arr2.indexOf(n) !== -1;
    });
}