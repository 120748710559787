import {api_request_promise, getRandomArbitrary, randomDate, intersect} from "../../functions"
import { useEffect } from "react";
import classnames from "classnames";

import ItemVk from "./item-vk";
import ItemTg from "./item-tg";
import EItemVk from "./e-item-vk";
import EItemTg from "./e-item-tg";

import React, {Component} from 'react';

class PageIndex extends Component {

    state = {
        showItemVk: false,
        showEItemVk: false,

        eItem: null,

        showItemTG: false,
        showEItemTG: false,

        items: []
    }

    componentDidMount() {
        this.props.setLoading(true)
        this.updateList().then(() => {
            this.props.setLoading(false)
        })
    }

    updateList(){
        return new Promise((resolve, reject) => {
            api_request_promise('get', 'sources/items', {}).then((resp) => {
                this.setState({...this.state,
                    items: resp.result,
                })
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    deleteItem(item){
        this.props.setLoading(true)
        api_request_promise('post', 'sources/remove', {id: item.id}).then((resp) => {
            this.updateList().then(() => {
                this.props.setLoading(false)
            })
        })
    }

    editItem(item){
        this.setState({...this.state, eItem: item})
        setTimeout(() => {
            if(item.type === 'vk'){
                this.setState({...this.state, showEItemVk: true})
            }
            if(item.type === 'tg'){
                this.setState({...this.state, showEItemTg: true})
            }
        }, 100)
    }

    renderList(){
        return this.state.items.map(item => {
            return (
                <tr className="hover:bg-gray-100">
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.type}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.type === 'vk' && item.data.vk_group_info ? item.data.vk_group_info.name : ""}
                        {item.type === 'vk' && !item.data.vk_group_info ? "?" : ""}
                        {item.type === 'tg' && item.data.tg_info ? item.data.tg_info.first_name : ""}
                        {item.type === 'tg' && item.data.tg_info ? " [@" + item.data.tg_info.username + ']' : ""}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.confirm === 1 ? <span className={"text-green-700 bg-green-200  py-1 px-3 rounded  text-xs"}>подтверждено</span> : <span className={"text-red-700 bg-red-200  py-1 px-3 rounded  text-xs"}>не подтверждено</span>}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {this.props.userData.user.role=== 'root'  ?
                            <div><a href="#" className="text-white py-1 px-3 rounded text-xs bg-gray-400 hover:bg-gray-500 mr-2" onClick={() => this.editItem(item)}>редактировать</a>
                                <a href="#" className="text-red-700 py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-300 hover:text-red-900" onClick={() => this.deleteItem(item)}>Удалить</a>
                            </div>    : null}
                    </td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <div className={"mb-5 bg-gray-200 border-t border-b border-gray-300 py-1 px-2"}>
                    <button
                        className="bg-gray-400 hover:bg-gray-500 text-sm text-white  py-1 px-2 rounded"
                        onClick={() => this.setState({...this.state, showItemVk: true})}
                        type="button">
                        Добавить ВК
                    </button>
                    &nbsp;
                    <button
                        className="bg-gray-400 hover:bg-gray-500 text-sm text-white  py-1 px-2 rounded"
                        onClick={() => this.setState({...this.state, showItemTg: true})}
                        type="button">
                        Добавить ТГ
                    </button>
                </div>

                <div className="bg-white rounded my-6">
                    <table
                        className="text-left w-full border-collapse">
                        <thead>
                        <tr>
                            <th className="py-4 px-6 font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Тип</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Название</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Статус</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light" style={{width: 100}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderList()}
                        </tbody>
                    </table>
                </div>

                {this.state.showItemVk ? <ItemVk updateList={this.updateList.bind(this)} setHook={()=>{this.setState({...this.state, showItemVk: false})}} {...this.props} /> : null}
                {this.state.showItemTg ? <ItemTg updateList={this.updateList.bind(this)} setHook={()=>{this.setState({...this.state, showItemTg: false})}} {...this.props} /> : null}

                {this.state.showEItemVk ? <EItemVk item={this.state.eItem} updateList={this.updateList.bind(this)} setHook={()=>{this.setState({...this.state, showEItemVk: false, eItem: null,})}} {...this.props} /> : null}
                {this.state.showEItemTg ? <EItemTg item={this.state.eItem} updateList={this.updateList.bind(this)} setHook={()=>{this.setState({...this.state, showEItemTg: false, eItem: null,})}} {...this.props} /> : null}
            </div>
        );
    }
}

export default PageIndex;
