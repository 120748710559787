import Modal from "../../ui/modal";

import React, {Component} from 'react';
import {api_request_promise, BACKEND_URL, flash_messages, getRandomString} from "../../functions"
import {Formik} from "formik";

class EItemTg extends Component {

    sendForm = (values, { setSubmitting }) => {
        this.props.setLoading(true)
        values.id = this.props.item.id
        api_request_promise('post', 'sources/save_tg', values).then((resp) => {
            this.props.setLoading(false);
            this.props.updateList();
            this.props.setHook();
            flash_messages("Источник успешно сохранен! ")
        }).catch((e)=>{
            setSubmitting(false);
            this.props.setLoading(false)
        })
    }

    render() {
        const item = this.props.item
        if(!item){
            return null;
        }
        let initData = {
            token: item.url_code,
            tags: item.tags ?? "",
            autoreplay: item.autoreplay ?? "",
            time_start: item.time_start ?? "",
            time_end: item.time_end ?? ""
        };

        return (
            <Formik
                initialValues={initData}
                validate={values => {
                    const errors = {};
                    if (!values.token) errors.token = 'Required';
                    return errors;
                }}
                onSubmit={this.sendForm}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal title={'Редактирование источника: Вконтакте'} setHook={this.props.setHook}  btn={{label: "Сохранить", handler: false}}>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Токен бота
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.token}
                                    type="text" name={"token"} placeholder="" />
                                <p className="text-red-500 text-xs">{errors.token && touched.token && errors.token}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Теги к диалогам, через запятую
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tags}
                                    type="text" name={"tags"} placeholder=""  />
                                <p className="text-red-500 text-xs">{errors.tags && touched.tags && errors.tags}</p>
                            </div>

                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Время работы
                                </label>
                                <div className={"flex"}>
                                    <input
                                        className="shadow w-1/2 appearance-none border mb-1 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.time_start}
                                        type="text" name={"time_start"} placeholder=""  />
                                    <span >&nbsp; - &nbsp;</span>
                                    <input
                                        className="shadow w-1/2  appearance-none border mb-1 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.time_end}
                                        type="text" name={"time_end"} placeholder=""  />
                                </div>
                                <p className="text-red-500 text-xs">{errors.time_start && touched.time_start && errors.time_start}{errors.time_end && touched.time_end && errors.time_end}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Текст авто-ответа
                                </label>
                                <textarea
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={"autoreplay"}
                                >
                                    {values.autoreplay}
                                </textarea>
                                <p className="text-red-500 text-xs">{errors.autoreplay && touched.autoreplay && errors.autoreplay}</p>
                            </div>

                        </Modal>
                    </form>
                )}
            </Formik>
        );
    }

}

export default EItemTg;