import { useState } from 'react';
import classNames from "classnames";
import {
    useLocation,
    Link
} from "react-router-dom";
import classnames from "classnames";

function NavBar({userData, socketConnect}) {
    let location = useLocation();
    var currentLocation = location.pathname;

    const logout = () => {
        localStorage.removeItem("auth_token")
        localStorage.removeItem("auth_data")
        localStorage.removeItem("auth_room")
        window.location.href = "/";
    }

    let style = ["text-gray-300 hover:text-gray-500 hover:cursor-pointer mr-5"];
    let styleActive = ["text-blue-500 font-semibold mr-5"];

    return (
        <div className={"xl:text-2xl lg:px-5 md:px-5 sm:text-xl flex justify-between mb-4"}>
            <div>
                <Link to={"/"} className={classNames(currentLocation === '/' ? styleActive : style)}>Диалоги</Link>
                {userData.user.role === 'root' ? <Link to={"/stats"} className={classNames(currentLocation === '/stats' ? styleActive : style)}>Статистика</Link> : null}
                {userData.user.role === 'root' ? <Link to={"/sources"} className={classNames(currentLocation === '/sources' ? styleActive : style)}>Источники</Link> : null}
                {userData.user.role === 'root' ? <Link to={"/setting"} className={classNames(currentLocation === '/setting' ? styleActive : style)}>Настройка</Link> : null}
                <Link to={"/templates"} className={classNames(currentLocation === '/templates' ? styleActive : style)}>Шаблоны ответа</Link>
            </div>
            <div>
                <a href={"/profile"} className={"hover:text-blue-500 hover:cursor-pointer"}>
                    {userData.user.email}
                    {socketConnect ?
                        <span className={classnames("bg-green-400 rounded-full w-2 h-2  inline-block ml-1")}></span>
                        :
                        <span className={classnames("bg-red-400 rounded-full w-2 h-2 inline-block ml-1")}></span>
                    }
                </a>
            </div>
        </div>
    )
}

export default NavBar;