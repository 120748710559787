import Modal from "../../ui/modal";

import React, {Component} from 'react';
import {api_request_promise, BACKEND_URL, flash_messages, getRandomString} from "../../functions"
import {Formik} from "formik";

class ItemTg extends Component {

    sendForm = (values, { setSubmitting }) => {
        this.props.setLoading(true)
        api_request_promise('post', 'sources/add_tg', values).then((resp) => {
            this.props.setLoading(false);
            this.props.updateList();
            this.props.setHook();
            flash_messages("Источник успешно добавлен!")
        }).catch((e)=>{
            setSubmitting(false);
            this.props.setLoading(false)
        })
    }

    render() {
        let initData = { token: '', tags: ''};
        if(this.props.item){
            initData = this.props.item
        }

        return (
            <Formik
                initialValues={initData}
                validate={values => {
                    const errors = {};
                    if (!values.token) errors.token = 'Required';
                    return errors;
                }}
                onSubmit={this.sendForm}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal title={'Добавление источника: Телеграм'} setHook={this.props.setHook}  btn={{label: "Сохранить", handler: false}}>
                            <div className="w-full mb-6">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Токен бота
                                    </label>
                                    <input
                                        className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.token}
                                        type="text" name={"token"} placeholder="" />
                                    <p className="text-red-500 text-xs">{errors.token && touched.token && errors.token}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Теги к диалогам, через запятую
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tags}
                                    type="text" name={"tags"} placeholder=""  />
                                <p className="text-red-500 text-xs">{errors.tags && touched.tags && errors.tags}</p>
                            </div>
                        </Modal>
                    </form>
                )}
            </Formik>
        );
    }

}

export default ItemTg;