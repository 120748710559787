import React, {createRef, useEffect, useState} from 'react';
import {api_request_file_promise, api_request_promise} from "../../functions";
import {Formik} from "formik";
import {useDropzone} from 'react-dropzone';

function SmallTemplate(props) {
    const [text, setText] = useState("");
    const [timer, setTimer] = useState(null);

    const [templates, setTemplates] = useState([])

    const loadTemplates = (text) => {
        return new Promise((resolve, reject) => {
            api_request_promise('post', 'templates/search', {text: text}).then((resp) => {
                setTemplates(resp.result)
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    const changeText = (text) => {
        setText(text)
        clearTimeout(timer)
        setTimer(setTimeout(async () => {
            await loadTemplates(text)
        }, 500))
    }

    useEffect(() => {
        loadTemplates('')
    }, []);

    const selectTemplate = (item) => {
        props.selectTemplate(item.text)
        api_request_promise('post', 'templates/use', {id: item.id}).then(data => {
            let items = JSON.parse(JSON.stringify(templates))
            items.map(item_s => {
                if(item.id === item_s.id){
                    item_s.cnt = data.result.cnt;
                }
                return item_s;
            })
            setTemplates(items)
        }).catch()
    }

    return (
        <div>
            {/*<div>*/}
            {/*    <input*/}
            {/*        className="appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"*/}
            {/*        onChange={(e) => changeText(e.target.value)}*/}
            {/*        value={text}*/}
            {/*        type="text" name={"text"} placeholder="" />*/}
            {/*</div>*/}
            <div>
                <ul className="mb-0 mt-2">
                    {templates.map(item => <li onClick={() => selectTemplate(item)} className="flex flex-row pb-1 border-bottom hover:bg-gray-100 px-1 rounded cursor-pointer">
                        <div className=" flex-grow">{item.name}</div>
                        <div>{item.cnt}</div>
                    </li>)}
                </ul>
            </div>
        </div>
    );
}

export default SmallTemplate;