import classNames from "classnames";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './App.css';

import Loading from "./ui/loading";
import NavBar from './ui/navbar';
import Ws from '@adonisjs/websocket-client';
import PageCabinetIndex from './pages/CabinetIndex';
import PageCabinetStats from './pages/CabinetStats';
import PageCabinetSetting from './pages/CabinetSetting';
import PageCabinetSources from './pages/CabinetSources';
import PageCabinetProfile from './pages/CabinetProfile';
import PageCabinetTemplates from './pages/CabinetTemplate';

import PageIndex from './pages/Index';
import PageLogin from './pages/Login';
import PageRegistration from './pages/Registration';
import {api_request_promise, flash_messages} from "./functions";

import React, {Component} from 'react';

class App extends Component {

    state = {
        userData: {
            authToken: null,
            auth: false,
            user: {},
            usersRoom: {},
            socketConnect: false,
        },
        socket: null,
        isLoading: false
    }

    componentDidMount() {
        let authToken = localStorage.getItem("auth_token");
        let authUser = localStorage.getItem("auth_user");
        if(authToken && authUser){
            api_request_promise('get', 'user/me').then((resp) => {
                const startData = {auth: true, user: resp.result.user,  usersRoom: resp.result.users_room, authToken: authToken}
                this.setState({...this.state, userData:startData})
                setTimeout(()=> {
                    this.initSocket()
                }, 500)
            }).catch((e)=>{
                localStorage.removeItem("auth_token");
                localStorage.removeItem("auth_user");
            })
        }
    }

    initSocket = () => {
        const socket = Ws('wss://helpdesc.neytin.com').withApiToken(this.state.userData.authToken).connect();


        this.setState({...this.state, socket: socket})

        socket.on('open', () => { console.log('Connection initialized'); this.setState({...this.state, socketConnect: true})  });
        socket.on('close', () => { console.log('Connection closed'); this.setState({...this.state, socketConnect: false}) });
        socket.on('error', (error) => { console.log(error); this.setState({...this.state, socketConnect: false})})

        const ws_room = socket.subscribe('room:'+this.state.userData.user.room_id)
        ws_room.on('message', (data) => {flash_messages(data.text, data.type) })
        ws_room.on('error', (error) => { console.log('ws_room error', error) })
        ws_room.on('close', () => { console.log('ws_room close')})
    }

    setUserData = (startData) => {
        this.setState({...this.state, userData:startData})
    }

    setIsLoading = (stat) => {
        this.setState({...this.state, isLoading: stat})
    }

    render() {
        return (
            <div className={classNames("container mx-auto", {"min-h-screen": this.state.userData.auth})}>
                <Loading state={this.state.isLoading}  />
                <Router>
                    { this.state.userData.auth && this.state.socket ?
                        <div className={" pt-3"}>
                            <NavBar userData={this.state.userData} socketConnect={this.state.socketConnect} />
                            <div>
                                <Switch>
                                    <Route path="/stats" render={props => <PageCabinetStats {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading} userData={this.state.userData} setUserData={this.setUserData}  />} />
                                    <Route path="/setting" render={props => <PageCabinetSetting {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading} userData={this.state.userData} setUserData={this.setUserData}  />} />
                                    <Route path="/sources" render={props => <PageCabinetSources {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading} userData={this.state.userData} setUserData={this.setUserData}  />} />
                                    <Route path="/profile" render={props => <PageCabinetProfile {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading} userData={this.state.userData} setUserData={this.setUserData}  />} />
                                    <Route path="/templates" render={props => <PageCabinetTemplates {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading} userData={this.state.userData} setUserData={this.setUserData}  />} />
                                    <Route path="/" render={props => <PageCabinetIndex {...props} socket={this.state.socket} setLoading={this.setIsLoading} isLoading={this.state.isLoading} userData={this.state.userData} setUserData={this.setUserData}  />} />
                                </Switch>
                            </div>
                        </div>
                        : null}
                    { !this.state.userData.auth ?
                        <Switch>
                            <Route path="/login" render={props => <PageLogin {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading}  userData={this.state.userData} setUserData={this.setUserData}   />} />
                            <Route path="/registration" render={props => <PageRegistration {...props} setLoading={this.setIsLoading} isLoading={this.state.isLoading}  userData={this.state.userData} setUserData={this.setUserData}   />} />
                            <Route path="/" render={props => <PageIndex {...props}  />} />
                        </Switch>
                        : null}
                </Router>
            </div>
        );
    }
}

export default App;
