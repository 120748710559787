import {api_request_promise} from "../../functions"
import {Formik} from "formik";

function PageLogin({setLoading, setUserData}) {

    const sendForm = (values, { setSubmitting }) => {
        setLoading(true)
        api_request_promise('post', 'user/auth', values).then((resp) => {
            localStorage.setItem("auth_token", resp.result.token)
            localStorage.setItem("auth_user", JSON.stringify(resp.result.user))
            setTimeout(()=>{
                setUserData({auth: true, user: resp.result.user})
                setSubmitting(false);
                window.location.href = '/'
            }, 1000)
        }).catch((e)=>{
            setSubmitting(false);
            setLoading(false)
        })
    }

    return (
        <div className="max-w-sm mx-auto pt-5 md:pt-10 lg:pt-10 xl:pt-10">
            <h1 className="mb-2 p-2 text-center text-lg text-gray-700 ">Авторизация</h1>
            <Formik
                initialValues={{ email: '', password: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.email) errors.email = 'Required';
                    if (!values.password) errors.password = 'Required';
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) errors.email = 'Invalid email address';
                    return errors;
                }}
                onSubmit={sendForm}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form className="bg-white shadow-md rounded px-8 pt-6 pb-8" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Email
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                type="email" name="email" required autoFocus placeholder="Email" />
                            <p className="text-red-500 text-xs">{errors.email && touched.email && errors.email}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Пароль
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                name="password" required type="password" placeholder="Пароль" />
                            <p className="text-red-500 text-xs">{errors.password && touched.password && errors.password}</p>
                        </div>
                        <div className="">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                type="submit"  disabled={isSubmitting}>
                                Войти
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default  PageLogin
