import {api_request_promise, getRandomArbitrary, randomDate, intersect} from "../../functions"

import React, {Component} from 'react';
import Item from "./item";

class PageTemplate extends Component {

    state = {
        items: [],
        showItem: false,
        editItem: false,
    }

    componentDidMount() {
        this.props.setLoading(true)
        this.loadDataFromServer().then(() => {
            this.props.setLoading(false)
        })
    }

    loadDataFromServer(){
        return new Promise((resolve, reject) => {
            api_request_promise('post', 'templates/search', {limit: 10000}).then((resp) => {
                this.setState({...this.state, items: resp.result})
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    editItem(item){
        this.setState({...this.state, editItem: item, showItem: true})
    }

    deleteItem(item){
        this.props.setLoading(true)
        api_request_promise('post', 'templates/remove', {id: item.id}).then((resp) => {
            this.loadDataFromServer().then(() => {
                this.props.setLoading(false)
            })
        })
    }

    renderList(){
        return this.state.items.map(item => {
            return (
                <tr className="hover:bg-gray-100">
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.name}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        <div>
                            <a href="#" className="text-white py-1 px-3 rounded text-xs bg-gray-400 hover:bg-gray-500 mr-2" onClick={() => this.editItem(item)}>редактировать</a>
                            <a href="#" className="text-red-700 py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-300 hover:text-red-900" onClick={() => this.deleteItem(item)}>Удалить</a>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <div className={"mb-5 bg-gray-200 border-t border-b border-gray-300 py-1 px-2"}>
                    <button
                        className="bg-gray-400 hover:bg-gray-500 text-sm text-white  py-1 px-2 rounded"
                        onClick={() => this.setState({...this.state, showItem: true})}
                        type="button">
                        Добавить шаблон ответа
                    </button>
                </div>
                <div className="bg-white rounded my-6">
                    <table
                        className="text-left w-full border-collapse">
                        <thead>
                        <tr>
                            <th className="py-4 px-6 font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Название</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light" style={{width: 100}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderList()}
                        </tbody>
                    </table>
                </div>

                {this.state.showItem ? <Item item={this.state.editItem} updateList={this.loadDataFromServer.bind(this)} setHook={()=>{this.setState({...this.state, showItem: false, editItem: false})}} {...this.props} /> : null}
            </div>
        );
    }
}

export default PageTemplate;
