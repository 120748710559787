import {api_request_promise, getRandomArbitrary, randomDate, intersect} from "../../functions"
import { useEffect } from "react";
import classnames from "classnames";
import { Scrollbars } from 'react-custom-scrollbars';

import React, {Component} from 'react';

class PageIndex extends Component {

    state = {

    }

    componentDidMount() {
        this.props.setLoading(true)
        this.props.setLoading(false)
    }

    render() {
        return (
            <div>
                <div className={"mb-5 bg-gray-200 border-t border-b border-gray-300 py-1 px-2"}>
                    {/*<button*/}
                    {/*    className="bg-gray-400 hover:bg-gray-500 text-sm text-white  py-1 px-2 rounded"*/}
                    {/*    type="submit">*/}
                    {/*    Проверить*/}
                    {/*</button>*/}
                </div>
            </div>
        );
    }
}

export default PageIndex;
