import Modal from "../../ui/modal";

import React, {Component} from 'react';
import {api_request_promise, BACKEND_URL, flash_messages, getRandomString} from "../../functions"
import {Formik} from "formik";

class Item extends Component {

    sendForm = (values, { setSubmitting }) => {
        this.props.setLoading(true)
        if(this.props.item) {
            values.id = this.props.item.id
        }
        api_request_promise('post', 'templates/save', values).then((resp) => {
            this.props.setLoading(false);
            this.props.updateList();
            this.props.setHook();
            flash_messages("Шаблон сохранен")
        }).catch((e)=>{
            setSubmitting(false);
            this.props.setLoading(false)
        })
    }

    render() {
        let initData = { name: '', text: ''};
        if(this.props.item){
            initData = this.props.item
        }

        return (
            <Formik
                initialValues={initData}
                validate={values => {
                    const errors = {};
                    if (!values.name) errors.name = 'Required';
                    if (!values.text) errors.color = 'Required';
                    return errors;
                }}
                onSubmit={this.sendForm}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal title={'Работа с шаблоном'} setHook={this.props.setHook}  btn={{label: "Сохранить", handler: false}}>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Название
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    type="text" name={"name"} placeholder="" />
                                <p className="text-red-500 text-xs">{errors.name && touched.name && errors.name}</p>
                            </div>

                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Шаблон
                                </label>
                                <textarea
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 h-52 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.text}
                                    name={"text"} placeholder="" />
                                <p className="text-red-500 text-xs">{errors.text && touched.text && errors.text}</p>
                            </div>

                        </Modal>
                    </form>
                )}
            </Formik>
        );
    }

}

export default Item;