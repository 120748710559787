import React, {createRef, useEffect, useState} from 'react';
import {api_request_file_promise, api_request_promise} from "../../functions";
import {Formik} from "formik";
import {useDropzone} from 'react-dropzone';
import {useRef} from "react/cjs/react.production.min";


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 5,
    border: '1px solid #eaeaea',
    marginRight: 8,
    width: 50,
    marginTop: '14px',
    height: 50,
    padding: 4,
    textAlign: 'center',
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const dropArea = {
    position: 'absolute',
    zIndex: 1,
    background: '#f2f2f2',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    padding: '16px',
    color: '#999',
    border: '3px #ccc dashed',
    opacity: .8
};

function Form(props) {
    const [files, setFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [defTextForm, setDefTextForm] = useState('');

    const formRef = createRef()

    const onDrop = acceptedFiles => {
        let n_files = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
        setFiles([...files, ...n_files]);
        setShow(false)
        props.change()
    }

    const onPasteFile = (e) => {
        const s_files = e.clipboardData.files
        if(s_files.length > 0){
            let n_files = Array.from(s_files).map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            setFiles([...files, ...n_files]);
            props.change()
        }
    }

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop: onDrop,
        accept: '.jpeg,.png,.jpg',
        noClick: true,
        noKeyboard: true,
        onDragOver: () => setShow(true),
        onDragLeave: () => setShow(false),
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    useEffect(() => {
        if(localStorage.key("dialog-message-" + props.selectedRoom.id)){
            let txt = localStorage.getItem("dialog-message-" + props.selectedRoom.id)
            if(txt !== ""){
                setDefTextForm(txt)
            }else{
                localStorage.removeItem("dialog-message-" + props.selectedRoom.id)
            }
        }
    }, [])

    const sendForm = (values, { setSubmitting, resetForm }) => {
        props.setLoading(true)
        setSubmitting(true);
        let formData = new FormData();
        formData.append("dialog_id", props.selectedRoom.id)
        formData.append("message", values.text.trim())
        files.map(file => {
            formData.append('file', file);
        })
        api_request_file_promise('dialogs/replay', formData).then((resp) => {
            setSubmitting(false);
            resetForm()
            setFiles([])
            setDefTextForm('')
            localStorage.removeItem("dialog-message-" + props.selectedRoom.id)
            props.setLoading(false)
        }).catch((e)=>{
            setSubmitting(false);
            props.setLoading(false)
        })
    }

    return (
        <div>
            <div className="flex flex-row items-center h-50 rounded-xl bg-white w-full px-4 relative" {...getRootProps()} onPaste={onPasteFile}>

                <Formik
                    initialValues={{ text: defTextForm}}
                    validate={values => {
                        const errors = {};
                        if (!values.text && files.length === 0) errors.text = 'Required';
                        return errors;
                    }}
                    enableReinitialize={true}
                    innerRef={formRef}
                    onSubmit={sendForm}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        setFieldValue
                      }) => (
                        <form   onSubmit={handleSubmit} className="flex-grow flex flex-row items-center">
                            <div className="flex-grow">
                                <div className="relative w-full">
                                    <textarea
                                        ref={props.itemRef}
                                        onKeyDown={(e) => {
                                            if(e.ctrlKey && e.key === 'Enter'){
                                                if (formRef.current) {
                                                    formRef.current.handleSubmit()
                                                }
                                            }
                                        }}
                                           onChange={(data) => {
                                               localStorage.setItem("dialog-message-" + props.selectedRoom.id,  data.target.value)
                                               handleChange(data)
                                           }}
                                           style={{fontSize: '14px'}}
                                           value={values.text}
                                            onBlur={handleBlur}
                                           name="text"
                                           className={"flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 p-2 h-28"}/>
                                </div>
                            </div>
                            <div className="ml-4">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 rounded-xl text-white px-3 py-3 flex-shrink-0">
                                    <span><svg className="w-5 h-5 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path></svg></span>
                                </button>
                                {show ? <div style={dropArea}>файлы сюда</div>:null}
                                <button
                                    onClick={() =>{open()}}
                                    type={'button'}
                                    className="flex items-center justify-center text-gray-400  bg-gray-200 hover:bg-gray-300 rounded-xl  px-3 py-3 hover:text-gray-600 mt-2  flex-shrink-0">
                                    <span>
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path></svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            <div>
                <input {...getInputProps()} />
                {files.length ? <aside style={thumbsContainer} className={"ml-4"}>
                    {thumbs}
                </aside> : null}
            </div>
        </div>
    );
}

export default Form;