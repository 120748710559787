import {api_request_promise, getRandomArbitrary, randomDate, intersect} from "../../functions"
import { useEffect } from "react";
import classnames from "classnames";
import { Scrollbars } from 'react-custom-scrollbars';

import React, {Component} from 'react';
import Item from "./item";
import User from "./user";

class PageIndex extends Component {

    state = {
        category: [],
        users: [],
        room: {},
        sources: [],
        showItem: false,
        showUserItem: false,
        editItem: false,
        editUserItem: false,
    }

    componentDidMount() {
        this.props.setLoading(true)
        this.loadDataFromServer().then(() => {
            this.updateListSources().then(() => {
                this.props.setLoading(false)
            })
        })
    }

    updateListSources(){
        return new Promise((resolve, reject) => {
            api_request_promise('get', 'sources/items', {}).then((resp) => {
                this.setState({...this.state,
                    sources: resp.result,
                })
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    loadDataFromServer(){
        return new Promise((resolve, reject) => {
            api_request_promise('post', 'setting/def').then((resp) => {
                this.setState({...this.state, category: resp.result.category, users: resp.result.users, room: resp.result.room})
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    editItem(item){
        this.setState({...this.state, editItem: item, showItem: true})
    }

    editUserItem(item){
        this.setState({...this.state, editUserItem: item, showUserItem: true})
    }

    deleteItem(item){
        this.props.setLoading(true)
        api_request_promise('post', 'category/remove', {id: item.id}).then((resp) => {
            this.loadDataFromServer().then(() => {
                this.props.setLoading(false)
            })
        })
    }

    renderList(){
        return this.state.category.map(item => {
            return (
                <tr className="hover:bg-gray-100">
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.name}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.is_start}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.is_end}
                    </td>

                    <td className="py-4 px-6 border-b border-grey-light">
                        {this.props.userData.user.role === 'root' ?
                        <div>
                            <a href="#" className="text-white py-1 px-3 rounded text-xs bg-gray-400 hover:bg-gray-500 mr-2" onClick={() => this.editItem(item)}>редактировать</a>
                            {item.is_start === 0 && item.is_end === 0 ? <a href="#" className="text-red-700 py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-300 hover:text-red-900" onClick={() => this.deleteItem(item)}>Удалить</a> : null}
                        </div> : null}
                    </td>
                </tr>
            )
        })
    }

    renderUserList(){
        return this.state.users.map(item => {
            return (
                <tr className="hover:bg-gray-100">
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.email}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {item.active ? "Активен" : "Выключен"}
                    </td>
                    <td className="py-4 px-6 border-b border-grey-light">
                        {this.state.room.users[item.id] ? this.state.room.users[item.id].access_sources.map(x => {
                            const src = this.state.sources.filter(y => y.id === parseInt(x))[0];
                            if(!src){
                                return '-1';
                            }
                            if(src.type === 'vk'){
                                return src.data.vk_group_info.name+'; '
                            }
                            if(src.type === 'tg'){
                                return src.data.tg_info.first_name+'; '
                            }
                        }) : "-2"}
                    </td>

                    <td className="py-4 px-6 border-b border-grey-light">
                        <a href="#" className="text-white py-1 px-3 rounded text-xs bg-gray-400 hover:bg-gray-500" onClick={() => this.editUserItem(item)}>редактировать</a>
                    </td>
                </tr>
            )
        })
    }


    render() {
        return (
            <div>
                <div className={"mb-5 bg-gray-200 border-t border-b border-gray-300 py-1 px-2"}>
                    {this.props.userData.user.role === 'root' ? <button
                        className="bg-gray-400 hover:bg-gray-500 text-sm text-white  py-1 px-2 rounded"
                        onClick={() => this.setState({...this.state, showItem: true})}
                        type="button">
                        Добавить категорию
                    </button> : null}
                </div>
                <div className="bg-white rounded my-6">
                    <table
                        className="text-left w-full border-collapse">
                        <thead>
                        <tr>
                            <th className="py-4 px-6 font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Название</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Первый</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Последний</th>
                            <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light" style={{width: 100}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderList()}
                        </tbody>
                    </table>
                </div>

                <div>

                    <div className={"mb-5 bg-gray-200 border-t border-b border-gray-300 py-1 px-2"}>
                        <button
                            className="bg-gray-400 hover:bg-gray-500 text-sm text-white  py-1 px-2 rounded"
                            onClick={() => this.setState({...this.state, showUserItem: true})}
                            type="button">
                            Добавить пользователя
                        </button>
                    </div>

                    <div className="bg-white rounded my-6">
                        <table
                            className="text-left w-full border-collapse">
                            <thead>
                            <tr>
                                <th className="py-4 px-6 font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Логин</th>
                                <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Статус</th>
                                <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light">Доступ</th>
                                <th className="py-4 px-6  font-bold uppercase text-sm text-gray-dark border-b border-grey-light" style={{width: 100}}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderUserList()}
                            </tbody>
                        </table>
                    </div>

                </div>

                {this.state.showItem ? <Item item={this.state.editItem} updateList={this.loadDataFromServer.bind(this)} setHook={()=>{this.setState({...this.state, showItem: false, editItem: false})}} {...this.props} /> : null}
                {this.state.showUserItem ? <User item={this.state.editUserItem} room={this.state.room} sources={this.state.sources} updateList={this.loadDataFromServer.bind(this)} setHook={()=>{this.setState({...this.state, showUserItem: false, editUserItem: false})}} {...this.props} /> : null}
            </div>
        );
    }
}

export default PageIndex;
