import {useState} from 'react';
import classNames from "classnames";

function Modal({title, children, btn = false, setHook}){
    const [isOpen, setIsOpen] = useState(false);

    setTimeout(()=>{
        setIsOpen(true)
    }, 100)

    const hideModal = () => {
        setHook(false)
    }

    return (
        <div className={classNames({"opacity-0 pointer-events-none": !isOpen}, "ease-in duration-100 modal fixed z-10 inset-0 overflow-y-auto")}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 uppercase mb-8" id="modal-headline">
                                    {title}
                                </h3>
                                <div className="mt-2">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        {btn ?
                            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                              <button type={btn.handler ? "button" : "submit"}
                                      onClick={()=> btn.handler ? btn.handler() : () => { void(0); } }
                                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 text-white font-medium shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                {btn.label}
                              </button>
                            </span>
                            : null}
                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                          <button type="button"
                                  onClick={()=>hideModal()}
                                  className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            Закрыть
                          </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal