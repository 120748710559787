import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {BACKEND_URL} from "../../functions";
import baguetteBox from 'baguettebox.js';
import 'baguettebox.js/dist/baguetteBox.min.css';


class Message extends Component {

    componentDidMount() {
        baguetteBox.run('.gallery');
    }

    render() {

        const message = this.props.message
        const dialog = this.props.dialog

        let operator = false;

        const isOperator = message.is_operator
        if(isOperator){
            operator = this.props.operators.filter(x => x.id === message.operator.id)
            if(operator.length > 0){
                operator = operator[0]
            }
        }

        function nl2br (str, is_xhtml) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        }

        const templateUser = (
            <div className="md:col-start-1 md:col-end-12 col-span-full md:p-3 pb-3 rounded-lg">
                <div className="flex flex-row items-center ">
                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-200 md:flex hidden flex-shrink-0" >
                        {dialog.source_type === 'vk' ? <img className={"rounded-full"} src={dialog.source_user.avatar} /> : dialog.source_user.name.substring(0, 1)}
                    </div>
                    <div className={message.is_unread ? "relative md:ml-3 bg-red-100 text-sm bg-white py-2 px-4 shadow rounded-xl break-all" : "relative md:ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl break-all"} >
                        <div>
                            {message.type === 'text' && message.content ? <div dangerouslySetInnerHTML={{ __html:nl2br(message.content) }} /> : null}
                            {message.type === 'image' && message.content ? <div className={"gallery"}><a href={(message.content.split('://').length === 1 ? BACKEND_URL+message.content : message.content)}><img style={{maxHeight: "300px"}} src={(message.content.split('://').length === 1 ? BACKEND_URL+message.content : message.content)} alt=""/></a></div> : null}
                            {message.type === 'file' && message.content ? <a target={"_blank"} href={(message.content.split('://').length === 1 ? BACKEND_URL+message.content : message.content)}>Ссылка на файл</a> : null}
                        </div>
                        <div className="absolute flex text-xs bottom-0 left-0 -mb-5 ml-2 text-gray-500 whitespace-nowrap" >
                            {new Date(message.created_at).toLocaleString()} &nbsp;
                            <div className={"cursor-pointer"} title={"Цитировать"} onClick={() => {
                                this.props.setMessageFromTemplate("> " + message.content.split("\n").join("\n> ") + "\n\n")
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        const templateOperator = (
            <div className="md:col-start-2 md:col-end-13 md:p-3 pb-3 col-span-full rounded-lg">
                <div className="flex items-center justify-start flex-row-reverse">
                    <div className="flex items-center justify-center  md:flex hidden h-10 w-10 rounded-full bg-blue-200 flex-shrink-0" >
                        O
                    </div>
                    <div className="relative md:mr-3 mr-1 text-sm bg-blue-100 py-2 px-4 shadow rounded-xl break-all" >
                        <div>
                            {message.type === 'text' && message.content ? <div dangerouslySetInnerHTML={{ __html:nl2br(message.content) }} /> : null}
                            {message.type === 'image' && message.content ? <div className={"gallery"}><a href={(message.content.split('://').length === 1 ? BACKEND_URL+message.content : message.content)}><img style={{maxHeight: "300px"}} src={(message.content.split('://').length === 1 ? BACKEND_URL+message.content : message.content)} alt=""/></a></div> : null}
                            {/*{message.type === 'image' ? <img src={(message.content.split('://').length === 1 ? BACKEND_URL+message.content : message.content)} alt=""/> : null}*/}
                        </div>
                        <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500 whitespace-nowrap"  >
                            {message.is_send === 0 ? <span onClick={() => this.props.reSendMessage(message)} className={"text-red-900 bg-red-100 px-1 cursor-pointer hover:bg-red-200 rounded mr-5"}>повторить попытку отправки</span> : null}
                            {message.is_send === 2 ? <span  className={"text-blue-900 bg-blue-100 px-1 rounded mr-5"}>отправляю...</span> : null}
                            {operator.email}, {new Date(message.created_at).toLocaleString()}
                        </div>
                    </div>
                </div>
            </div>
        )
        return isOperator ? templateOperator : templateUser
    }
}

Message.propTypes = {};

export default Message;