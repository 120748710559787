import Modal from "../../ui/modal";

import React, {Component} from 'react';
import {api_request_promise, BACKEND_URL, flash_messages, getRandomString} from "../../functions"
import {Formik} from "formik";

class Item extends Component {

    sendForm = (values, { setSubmitting }) => {
        console.log(values)
        this.props.setLoading(true)
        if(this.props.item) {
            values.id = this.props.item.id
        }
        api_request_promise('post', 'setting/subuser', values).then((resp) => {
            this.props.setLoading(false);
            this.props.updateList();
            this.props.setHook();
            flash_messages("Пользователь сохранена")
        }).catch((e)=>{
            setSubmitting(false);
            this.props.setLoading(false)
        })
    }

    render() {
        let initData = {id: '', email: '', active: 0, password: '', room_access: []};
        if(this.props.item){
            initData = this.props.item
            initData['room_access'] = this.props.room.users[initData.id.toString()] ? this.props.room.users[initData.id.toString()].access_sources : []
        }

        return (
            <Formik
                initialValues={initData}
                validate={values => {
                    const errors = {};
                    if (!values.email) errors.email = 'Required';
                    if (values.active === '') errors.active = 'Required';
                    if (!values.password) errors.password = 'Required';

                    return errors;
                }}
                onSubmit={this.sendForm}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal title={'Работа с пользователем'} setHook={this.props.setHook}  btn={{label: "Сохранить", handler: false}}>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Почта
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    type="text" name={"email"} placeholder="" />
                                <p className="text-red-500 text-xs">{errors.email && touched.email && errors.email}</p>
                            </div>

                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Пароль
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    type="text" name={"password"} placeholder="" />
                                <p className="text-red-500 text-xs">{errors.password && touched.password && errors.password}</p>
                            </div>

                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Активность
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.active}
                                    type="number" name={"active"} placeholder="" />
                                <p className="text-red-500 text-xs">{errors.active && touched.active && errors.active}</p>
                            </div>

                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Доступ к источникам
                                </label>

                                {this.props.sources.map(src => {
                                    let name = "";
                                    if(src.type === 'vk'){
                                        name = src.data.vk_group_info.name
                                    }
                                    if(src.type === 'tg'){
                                        name = src.data.tg_info.first_name
                                    }

                                    return (
                                        <p>
                                            <label>
                                                <input type="checkbox" name={"room_access"} onChange={handleChange} defaultChecked={initData.room_access.includes(src.id.toString())} value={src.id}/> {name}
                                            </label>
                                        </p>
                                    )
                                })}

                            </div>

                        </Modal>
                    </form>
                )}
            </Formik>
        );
    }

}

export default Item;