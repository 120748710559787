import {api_request_promise} from "../../functions"
import {Formik} from "formik";
import { useEffect } from "react";

import React, {Component} from 'react';

class PageIndex extends Component {
    render() {
        return (
            <div>
                <h1 className="text-2xl mb-5">Название страницы</h1>

            </div>
        );
    }
}

export default PageIndex;
