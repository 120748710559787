import {api_request_promise, getRandomArbitrary, randomDate, intersect, flash_messages} from "../../functions"
import classnames from "classnames";
import ItemRoom from "./room";
import FormReplay from "./form";
import FormSmallTemplate from "./small_template";
import ItemMessage from "./message";
import { Scrollbars } from 'react-custom-scrollbars';

import React, {Component} from 'react';

class PageIndex extends Component {

    state = {
        category: [],
        tags: [],
        items: [],
        messages: [],
        operators: [],
        filter: {category: null, tags: [], word: "",},
        selectedRoom: null,
        timer: null,
        needReHeight: true,
        selectedRoomDoWork: false,
        user_works: {},
        template: "",
        refInputMessageText: React.createRef(),
        socketConnect: false,
        timerSocket: null,
        playNotify: false
    }
    refListMessages = null
    refAreaInput = null
    refDropZone = null

    audio = new Audio('/tweet.mp3')

    togglePlayNotify = () => {
        this.setState({...this.state,  playNotify: !this.state.playNotify }, () => {
            this.state.playNotify ? this.audio.play() : this.audio.pause();
        });
    }

    componentDidMount() {
        this.audio.addEventListener('ended', () => this.setState({...this.state, playNotify: false }));
        this.refListMessages = React.createRef();
        this.refAreaInput = React.createRef();
        this.refDropZone = React.createRef();
        this.props.setLoading(true)
        this.setState({...this.state, operators: Object.values(this.props.userData.usersRoom) ?? []})
        this.runSocketListen();
        setTimeout(() => {
            try{
                this.socketListener()
            }catch (e) {}
        }, 1000)
        this.loadMainDataFromServer().then(() => {
            this.props.setLoading(false)
            if(window.location.hash !== ""){
                this.changeUrlHash(window.location.hash)
            }
        })
    }

    changeUrlHash(hash){
        try{
            const dialog = hash.split("#")[1].split("ialog-")[1]
            if(dialog){
                this.selectRoom({id: dialog})
            }
        }catch (err){
        }
    }

    runSocketListen(){
        try{
            this.props.socket.on('open', () => {
                this.socketListener()
            });
        }catch (e) {
            // setTimeout( () => {
            //     this.runSocketListen();
            // }, 1000)
        }
    }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({...this.state, playNotify: false }));
        try{
            const socket_gl = this.props.socket;
            const socket = socket_gl.getSubscription('dialogs:'+this.props.userData.user.id)
            socket.off("new_message", ()=>{})
            socket.off("dialog_no_do_work", ()=>{})
            socket.off("dialog_change", ()=>{})
            socket.off("dialog_do_work", ()=>{})
            socket.off("dialog_update", ()=>{})
            socket.close()
        }catch (e){
            console.log(e)
        }
    }

    socketListener(connect = true){
        const socket_gl = this.props.socket;
        const socket = socket_gl.subscribe('dialogs:'+this.props.userData.user.id)
        socket.on('open', () => { console.log('dialogs socket open'); this.setState({...this.state, socketConnect: true}) })
        socket.on('error', (error) => {
            console.log('dialogs socket error');
            this.setState({...this.state, socketConnect: false})
        })
        socket.on('close', () => {
            console.log('dialogs socket close');
            this.setState({...this.state, socketConnect: false})

        })

        socket.on('message', (data) => { flash_messages(data.text, data.type) })

        socket.on("new_message", (data) => {
            let items = JSON.parse(JSON.stringify(this.state.items))
            let messages = JSON.parse(JSON.stringify(this.state.messages))
            if(items.filter(x => x.id === data.dialog.id).length){
                items[items.findIndex(x => x.id === data.dialog.id)] = data.dialog;
            }else{
                items.push(data.dialog)
            }
            if(this.state.selectedRoom && this.state.selectedRoom.id === data.dialog.id){
                messages.unshift(data.message)
                setTimeout(() => {
                    if(this.refListMessages){
                        // this.refListMessages.scrollToBottom()
                    }
                }, 1000)
                if(!data.message.is_operator){
                    if(this.state.filter.tags.length > 0){
                        if(intersect(this.state.filter.tags, data.dialog.tags).length > 0){
                            this.togglePlayNotify()
                        }
                    }else{
                        this.togglePlayNotify()
                    }
                }
            }else{
                if(!data.message.is_operator){
                    if(this.state.filter.tags.length > 0){
                        if(intersect(this.state.filter.tags, data.dialog.tags).length > 0){
                            flash_messages(`${data.dialog.source_user.name}: ${data.message.content}`, 's')
                            this.togglePlayNotify()
                        }
                    }else{
                        flash_messages(`${data.dialog.source_user.name}: ${data.message.content}`, 's')
                        this.togglePlayNotify()
                    }
                }
            }
            // this.loadCategories()
            // this.loadTags()
            this.setState({...this.state, items: items, messages: messages, needReHeight: true})
        })
        socket.on("dialog_no_do_work", (data) => {
            if(data.user.id !== this.props.userData.user.id){
                this.updateRoom(data.dialog)
                let user_works = JSON.parse(JSON.stringify(this.state.user_works))
                if(!!user_works[data.user.id]){
                    user_works[data.user.id] = null;
                }
                this.setState({...this.state, user_works: user_works})
            }
        })
        socket.on("dialog_change", (data) => {
            if(data.user.id !== this.props.userData.user.id){
                let user_works = JSON.parse(JSON.stringify(this.state.user_works))
                if(!!user_works[data.user.id]){
                    user_works[data.user.id] = null;
                }
                this.setState({...this.state, user_works: user_works})
            }
        })
        socket.on("dialog_do_work", (data) => {
            if(data.user.id !== this.props.userData.user.id){
                this.updateRoom(data.dialog)
                let user_works = JSON.parse(JSON.stringify(this.state.user_works))
                user_works[data.user.id] = data;
                this.setState({...this.state, user_works: user_works})
            }
        })
        socket.on("dialog_update", (data) => {
            if(data.user.id !== this.props.userData.user.id){
                this.updateRoom(data.dialog)
                this.loadTags()
                this.loadCategories()
            }
        })

    }

    loadCategories(){
        return new Promise((resolve, reject) => {
            api_request_promise('get', 'category/items').then((resp) => {
                let  categories = resp.result;
                categories.unshift({color: 'gray', id: -1, name: "*", })
                this.setState({...this.state,
                    category: categories,
                })
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    loadTags(){
        return new Promise((resolve, reject) => {
            api_request_promise('get', 'dialogs/tags').then((resp) => {
                this.setState({...this.state,
                    tags: resp.result,
                })
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    loadItems(){
        return new Promise((resolve, reject) => {
            api_request_promise('get', 'dialogs/items', {filter: this.state.filter}).then((resp) => {
                let t_items = resp.result;
                t_items.map(x => {
                    x.updated_at = x.updated_at.replace(" ", "T") + ".000Z";
                    return x
                })
                this.setState({...this.state,
                    items: t_items,
                })
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    loadMainDataFromServer(){
        console.log("main update")
        return new Promise((resolve, reject) => {
            this.loadCategories().then(()=>{
                this.loadTags().then(()=>{
                    this.loadItems().then(()=>{
                        resolve()
                    }).catch(e => {
                        reject(e)
                    })
                }).catch(e => {
                    reject(e)
                })
            }).catch(e => {
                reject(e)
            })
        })
    }

    searchDialog(){
        let word = prompt("Ведите текст поиска", this.state.filter.word)
        this.setFilter('word', word === null ? "" : word)
    }

    setFilter(type, id){
        let filter_now = JSON.parse(JSON.stringify(this.state.filter))
        if(type === 'category'){
            if(filter_now.category === id){
                filter_now.category = null;
            }else{
                filter_now.category = id;
            }
        }
        if(type === 'tags'){
            if(filter_now.tags.filter(x => x === id).length){
                filter_now.tags.splice(filter_now.tags.findIndex(x => x === id), 1)
            }else{
                filter_now.tags.push(id)
            }
        }
        if(type === 'word'){
            filter_now.word = id;
        }
        this.setState({...this.state, filter: filter_now, selectedRoom: null})
        this.props.setLoading(true)
        setTimeout(() => {
            this.loadItems().then(()=>{
                this.props.setLoading(false)
            })
        }, 100)
    }

    roomDoWork(){
        api_request_promise('post', 'dialogs/do_work', {dialog_id: this.state.selectedRoom.id}).then((resp) => {
            setTimeout(()=>{
                this.updateRoom(resp.result.dialog)
                this.setState({...this.state, selectedRoomDoWork: true})
                this.reHeightAreaMessages()
                try{
                    setTimeout(() => {
                        this.state.refInputMessageText.current.focus()
                    }, 100);
                }catch (e) {

                }
            }, 100)
        })
    }

    roomNoDoWork(){
        api_request_promise('post', 'dialogs/no_do_work', {dialog_id: this.state.selectedRoom.id}).then((resp) => {
            setTimeout(()=>{
                this.updateRoom(resp.result.dialog)
                this.setState({...this.state, selectedRoomDoWork: false})
                this.reHeightAreaMessages()
            }, 100)
        })
    }

    selectRoom(room){
        if(this.state.selectedRoom && this.state.selectedRoom.id === room.id){
            this.refListMessages.scrollToBottom()
        }else{
            window.history.pushState(null, null, "/#dialog-" + room.id);
            this._selectRoom(room)
        }
    }

    _selectRoom(room){
        this.props.setLoading(true)
        this.setState({...this.state, selectedRoom: null})
        api_request_promise('get', 'dialogs/messages', {dialog_id: room.id}).then((resp) => {
            setTimeout(()=>{
                this.updateRoom(resp.result.dialog)
                this.setState({...this.state, messages: resp.result.messages, selectedRoom: resp.result.dialog, selectedRoomDoWork: false, needReHeight: true})
                if(resp.result.dialog.is_unread === 0){
                    this.setState({...this.state, messages: resp.result.messages, selectedRoom: resp.result.dialog, selectedRoomDoWork: true, needReHeight: true})
                }
                this.props.setLoading(false)
            }, 100)
        })
    }

    updateRoom(data){
        let items = JSON.parse(JSON.stringify(this.state.items))

        data.updated_at = data.updated_at.replace(" ", "T") + ".000Z";

        if(items.filter(x => x.id === data.id).length){
            items[items.findIndex(x => x.id === data.id)] = data;
            if(this.state.selectedRoom && this.state.selectedRoom.id === data.id){
                this.setState({...this.state, items: items, selectedRoom: data})
            }else{
                this.setState({...this.state, items: items})
            }
        }else{
            this.loadItems()
        }
    }

    reSendMessage(message){
        let items_temp = JSON.parse(JSON.stringify(this.state.messages));
        let items = []
        items_temp.map((item) => {
            if(item.id === message.id){
                item.is_send = 2;
            }
            items.push(item)
        })
        this.setState({...this.state, messages: items, needReHeight: false})
        // запрос на отправку и получение результата
        api_request_promise('post', 'dialogs/re_send_message', {dialog_id: this.state.selectedRoom.id, message_id: message.id}).then((resp) => {
            let items = []
            items_temp.map((item) => {
                if(item.id === message.id){
                    item = resp.result.message;
                }
                items.push(item)
            })
            this.setState({...this.state, messages: items})
        })
    }

    renderRoomsList(){
        let items = []

        let items_temp = JSON.parse(JSON.stringify(this.state.items));
        items_temp.sort((a, b)=>{
            if(a.is_unread === 0 && b.is_unread > 0){
                return 2
            }
            if(a.is_unread > 0 && b.is_unread === 0){
                return -2
            }

            if(a.is_noreplay === 0 && b.is_noreplay > 0){
                return 1
            }
            if(a.is_noreplay > 0 && b.is_noreplay === 0){
                return -1
            }
            return Date.parse(b.updated_at) - Date.parse(a.updated_at);
        })

        let work_user = {};
        Object.values(this.state.user_works).map(item => {
            if(item !== null){
                work_user[item.dialog.id] = item.user
            }
        })

        items_temp.map((item, i) => {
            if(this.state.filter.category !== null){
                if(this.state.filter.category > 0){
                    if(item.category_id !== this.state.filter.category){
                        return
                    }
                }else{
                    if(item.is_noreplay !== 1 && item.is_unread !== 0){
                        return
                    }
                }
            }
            if(this.state.filter.tags.length > 0){
                if(intersect(this.state.filter.tags, item.tags).length !== this.state.filter.tags.length){
                    return
                }
            }

            const category = this.state.category.filter(x => x.id === item.category_id)[0]

            items.push(
                <ItemRoom
                    key={item.id}
                    category={category}
                    data={item}
                    workUser={work_user[item.id]}
                    onClick={() => this.selectRoom(item)} isSelected={this.state.selectedRoom && this.state.selectedRoom.id === item.id}
                />)
        })

        return items;
    }

    renderMessageList(){
        let items = []
        let items_temp = JSON.parse(JSON.stringify(this.state.messages));
        items_temp = items_temp.sort((a, b)=>{
            return a.created_at - b.created_at;
        })
        let prev_date = null;
        items_temp.reverse().map((item, i) => {
            item.created_at = item.created_at.replace(" ", "T") + ".000Z";
            let this_date = new Date(item.created_at).toLocaleString().substr(0, 5);
            if(this_date !== prev_date){
                items.push(<hr className={"col-start-1 bg-gray-200 col-end-13 mt-3 mb-1  pb-1 border-none"} />);
                prev_date =  this_date;
            }
            items.push(<ItemMessage
                key={item.id}
                setMessageFromTemplate={this.setMessageFromTemplate}
                operators={this.state.operators}
                dialog={this.state.selectedRoom}
                reSendMessage={mess => this.reSendMessage(mess)}
                message={item} />)
        })

        if(this.state.needReHeight){
            let timer = setTimeout(() => {
                try{
                    this.reHeightAreaMessages()
                    // this.refListMessages.scrollToBottom()
                    clearTimeout(timer)
                }catch (e) {
                    console.log(e)
                }
            }, 50)
        }

        return items;
    }

    selectCategory = (value)=>{
        return new Promise((resolve, reject) => {
            api_request_promise('post', 'dialogs/update_category', {id:this.state.selectedRoom.id, category: value}).then((resp) => {
                this.loadCategories()
                this.updateRoom(resp.result.dialog)
                resolve()
            }).catch((e)=>{
                reject(e)
            })
        })
    }

    setComments = (e)=>{
        clearTimeout(this.state.timer)
        let selRoom = JSON.parse(JSON.stringify(this.state.selectedRoom))
        selRoom.comments = e.target.value;
        this.setState({...this.state, selectedRoom: selRoom, timer: setTimeout(() => {
                api_request_promise('post', 'dialogs/update_comments', {id:this.state.selectedRoom.id, comments: this.state.selectedRoom.comments}).then((resp) => {
                    // this.updateRoom(resp.result.dialog)
                })
            },  500)})
    }

    addNewTags = (tag = false) => {
        let nameTag = ""
        if(tag === false){
            nameTag = prompt("Введите тег, либо несколько, через запятую")
        }else{
            nameTag = tag
        }
        if(nameTag){
            api_request_promise('post', 'dialogs/add_tags', {id:this.state.selectedRoom.id, tags: nameTag}).then((resp) => {
                this.loadTags()
                this.updateRoom(resp.result.dialog)
            })
        }
    }

    removeTag = (tag) => {
        api_request_promise('post', 'dialogs/remove_tag', {id:this.state.selectedRoom.id, tag: tag}).then((resp) => {
            this.loadTags()
            this.updateRoom(resp.result.dialog)
        })
    }

    reHeightAreaMessages = () => {
        const inpFormArea = this.refAreaInput.current.clientHeight;
        this.refListMessages.container.style.height = "calc(100vh - "+(parseInt(inpFormArea)+275)+"px)";
    }

    getSourceInfo = () => {
        if(this.state.selectedRoom.source_type === 'vk'){
            return  <span>[{this.state.selectedRoom.source_type}] {this.state.selectedRoom.source ? this.state.selectedRoom.source.data.vk_group_info.name : null}</span>
        }else if(this.state.selectedRoom.source_type === 'tg'){
            return  <span>[{this.state.selectedRoom.source_type}] {this.state.selectedRoom.source ? this.state.selectedRoom.source.data.tg_info.first_name : null}</span>
        }
    }

    setNativeValue = (element, value) => {
        const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
        const prototype = Object.getPrototypeOf(element);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(
            prototype,
            "value"
        ).set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value);
        } else {
            valueSetter.call(element, value);
        }
    };

    setMessageFromTemplate = (text) => {
        if(this.state.refInputMessageText.current){
            let el = this.state.refInputMessageText.current
            const [start, end] = [el.selectionStart, el.selectionEnd];
            let value = el.value;
            if(start === 0){
                value += text;
            }else{
                el.setRangeText(text, start, end, 'select');
                value = el.value
            }

            const event = new Event("change", {
                bubbles: true,
            })
            setTimeout(() => {
                this.setNativeValue(el, value);
                el.dispatchEvent(event);
                el.focus();
                el.value +=' ';
                el.value = el.value.slice(0, -1);
            }, 100)
        }
    }

    render() {
        return (
            <div>
                <div className={"mb-5 bg-gray-200 border-t border-b border-gray-300 py-1 px-2 flex justify-between"}>
                    <Scrollbars style={{ height: '140px' }}  autoHide>
                    <div className='flex flex-wrap'>
                        {this.state.category.map((item) => {
                            let styleDefault = `m-1 bg-${item.color}-400 border-l-4 border-${item.color}-600 rounded hover:bg-${item.color}-500 text-white px-3 text-sm leading-loose cursor-pointer`
                            let styleSelected = ""
                            if(this.state.filter.category === item.id){
                                styleSelected = `bg-${item.color}-600`
                            }
                            if(item.id < 0){
                                styleDefault += " grid place-content-center";
                            }
                            return (
                                <span
                                    key={item.id}
                                    onClick={()=>this.setFilter('category', item.id)}
                                    className={classnames(styleDefault, styleSelected)}>
                                    {item.id > 0 ? item.name : <span className={classnames("bg-red-400 rounded-full w-2 h-2 block")}></span>}
                                    {item.__meta__ && item.__meta__.dialogs_count && item.__meta__.dialogs_count > 0 ? (<sup>{item.__meta__.dialogs_count}</sup>) : null}
                                </span>
                            )
                        })}
                        {this.state.tags.map((item) => {
                            return (
                                <span
                                    key={item}
                                    onClick={()=>this.setFilter('tags', item)}
                                    className={classnames({"m-1 bg-gray-200 hover:bg-gray-100 text-gray-500 rounded-full px-2 text-sm leading-loose cursor-pointer": true, "bg-gray-300": this.state.filter.tags.filter(x => x === item).length})}>#{item}</span>
                            )
                        })}
                    </div>
                    </Scrollbars>
                    <div className={""}>
                        <button
                            onClick={() => this.searchDialog()}
                            type={'button'}
                            className="flex  items-center justify-center text-gray-400  bg-gray-200 hover:bg-gray-300 rounded-xl  px-1 m-1 hover:text-gray-600">
                            {this.state.filter.word ? <span className={"text-sm leading-loose cursor-pointer"}>{this.state.filter.word} </span> : ""}&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className={"grid grid-cols-12 mb-5 lg:px-1 md:px-1"}>
                    <div className={"lg:col-span-3 md:col-span-3 xl:col-span-3 col-span-4  2xl:col-span-2"}>
                        <Scrollbars style={{ height: 'calc(100vh - 235px)' }}  autoHide>
                            { this.renderRoomsList()}
                        </Scrollbars>
                    </div>
                    <div className={"lg:col-span-6 md:col-span-6 xl:col-span-6 col-span-8 2xl:col-span-7"}>
                        {this.state.selectedRoom ?
                            <div className={"bg-white ms:ml-5 ml-1 md:ml-2 p-3 rounded"}>
                                <div>
                                    <div className="flex flex-col flex-auto h-full">
                                        <div className="flex flex-col flex-auto flex-shrink-0 h-full p-0">
                                            <div className="flex flex-col h-full overflow-x-auto mb-4">
                                                <Scrollbars ref={c => { this.refListMessages = c }} autoHide>
                                                    <div className="flex flex-col h-full">
                                                        <div className="grid grid-cols-12 gap-y-2">
                                                            {this.renderMessageList()}
                                                        </div>
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                            <div ref={this.refAreaInput}>
                                                {this.state.selectedRoom ?
                                                    <div>
                                                        {!this.state.selectedRoomDoWork ?
                                                            <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
                                                                <button onClick={() => this.roomDoWork()} className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">начать работу</button>
                                                            </div> :
                                                            <div>
                                                                <FormReplay itemRef={this.state.refInputMessageText} setLoading={this.props.setLoading} change={this.reHeightAreaMessages} selectedRoom={this.state.selectedRoom} />
                                                            </div>
                                                        }
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className={"lg:col-span-3 md:col-span-3 xl:col-span-3 2xl:col-span-3 text-sm items-stretch hidden md:flex flex flex-col"}>
                        <Scrollbars style={{ height: 'calc(100vh - 235px)' }}  autoHide>
                            {this.state.selectedRoom ?
                                <div className={"bg-white ml-5 md:ml-2 p-3  mb-2 rounded"}>
                                    <p>
                                        {this.state.selectedRoom.source_type === 'vk' ?
                                            <a className={"text-blue-400"} href={"https://vk.com/id" + this.state.selectedRoom.source_user.id} target="_blank">{this.state.selectedRoom.source_user.name}</a>
                                            :
                                            this.state.selectedRoom.source_user.name}
                                    </p>
                                    <p>
                                        Источник: {this.getSourceInfo()}
                                    </p>
                                    <hr className={"mt-3 mb-5"}/>

                                    <div className={"flex"}>
                                        <div className="flex-grow">
                                            <select className={"w-full p-2 rounded bg-gray-100"} onChange={(e) => this.selectCategory(e.target.value)} value={this.state.selectedRoom.category_id}>
                                                {this.state.category.map((item) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="flex-initial">
                                            <button className={`m-1 bg-${this.state.category[this.state.category.length - 1].color}-400 border-l-4 border-${this.state.category[this.state.category.length - 1].color}-600 rounded hover:bg-${this.state.category[this.state.category.length - 1].color}-500 text-white px-3 text-sm leading-loose cursor-pointer`} onClick={() => this.selectCategory(this.state.category[this.state.category.length - 1].id)}>F</button>
                                        </div>
                                    </div>

                                    <textarea cols="30" rows="5" className={"w-full p-2 rounded bg-gray-100 mt-3"} placeholder={"Комментарйи к диалогу"} onChange={this.setComments} value={this.state.selectedRoom.comments}></textarea>

                                    <div className={"mt-3 flex flex-wrap"}>
                                        {this.state.selectedRoom.tags.map(tag => {
                                            return <span className="m-1 bg-gray-300 hover:bg-gray-200 text-gray-600 rounded-full px-3 py-1 text-xs cursor-pointer" onClick={() => this.removeTag(tag)}>{tag}</span>
                                        })}
                                    </div>
                                    <div className={"mt-1 flex flex-wrap"}>
                                        <span className="m-1 bg-gray-200 hover:bg-gray-300 text-gray-400 rounded-full px-3 py-1 text-xs cursor-pointer" onClick={() => this.addNewTags(false)}>+</span>
                                        {this.state.tags.filter(x => this.state.selectedRoom.tags.indexOf(x) === -1).sort(function(a,b){
                                            return a.toLowerCase().localeCompare(b.toLowerCase());
                                        }).map((item) => {
                                            return (
                                                <span  key={item}
                                                       onClick={()=>this.addNewTags(item)}
                                                       className="m-1 bg-gray-100 hover:bg-gray-200 text-gray-400 rounded-full px-3 py-1 text-xs cursor-pointer">{item}</span>
                                            )
                                        })}
                                    </div>

                                    <hr className={"mt-3 mb-5"}/>

                                    {!this.state.selectedRoomDoWork ? null : <div className="mt-5 rounded-xl bg-white w-full">
                                        <button onClick={() => this.roomNoDoWork()} className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">пометить не прочтенным</button>
                                    </div>}

                                </div>
                                : null}

                            <div className={"bg-white ml-5 md:ml-2 p-3 rounded flex-grow"}>
                                <FormSmallTemplate selectTemplate={this.setMessageFromTemplate} />
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        );
    }
}


export default PageIndex;
