import React from 'react';
import classnames from 'classnames';

function Item(props){

    let color = "indigo"

    try{
        color = props.category.color
    }catch (e){

    }


    const styleDefault = `bg-white px-2 py-1 rounded mb-1 cursor-pointer hover:bg-gray-200 relative border-l-4 border-${color}-600`
    let styleSelected = ""
    if(props.isSelected){
        styleSelected = `border bg-gray-200`
    }

    return (
        <div
            className={classnames(styleDefault, styleSelected)} onClick={() => props.onClick()}>
            <div className={"mt-1"}>
                <div className={"text-sm flex justify-between"}>
                    <div className={"mb-1 flex"}>
                        <div className=" hidden md:flex items-center justify-center h-5 w-5 rounded-full bg-gray-200 flex-shrink-0 mr-2" >
                            {props.data.source_type === 'vk' ? <img className={"rounded-full"} src={props.data.source_user.avatar} /> : props.data.source_user.name.substring(0, 1)}
                        </div>
                        {props.data.source_user.name.substring(0, 30)}</div>
                    <div className={"text-gray-400 text-xs flex items-center"}>
                        <div className={"lg:block md:hidden italic"}>
                            {new Date(props.data.updated_at).toLocaleDateString() === new Date().toLocaleDateString() ? new Date(props.data.updated_at).toLocaleTimeString().substr(0, 5) : ""}
                            {new Date(props.data.updated_at).toLocaleDateString() !== new Date().toLocaleDateString() ? (new Date(props.data.updated_at).toLocaleDateString()).substr(0, 5) : ""}
                        </div>
                        <div className={""}>
                            {props.data.is_unread ? <span className={classnames("bg-blue-400 rounded-full w-2 h-2 block ml-1")}></span>: null}
                            {props.data.is_unread === 0  && props.data.is_noreplay > 0? <span className={classnames("bg-red-400 rounded-full w-2 h-2 block ml-1")}></span>: null}
                        </div>
                    </div>
                </div>
                <div className={"lg:block md:hidden"}>
                    {props.data.tags.map(item => {
                        return (
                            <span key={item} className="bg-gray-200 rounded-full text-gray-500 px-2 mr-1 text-xs">{item}</span>
                        )
                    })}
                </div>
                {props.workUser && props.workUser !== null ? <div className={"absolute right-2 -bottom-1"}>
                    <span className="text-xs italic text-gray-400">{props.workUser.email}...</span>
                </div> : null}
            </div>
        </div>
    )
}

export default Item;