import Modal from "../../ui/modal";

import React, {Component} from 'react';
import {api_request_promise, BACKEND_URL, flash_messages, getRandomString} from "../../functions"
import {Formik} from "formik";

class ItemVk extends Component {

    sendForm = (values, { setSubmitting }) => {
        this.props.setLoading(true)
        api_request_promise('post', 'sources/add_vk', values).then((resp) => {
            this.props.setLoading(false);
            this.props.updateList();
            this.props.setHook();
            flash_messages("Источник успешно добавлен! Подтвердите сервер на странице Вконтакте!")
        }).catch((e)=>{
            setSubmitting(false);
            this.props.setLoading(false)
        })
    }

    render() {
        let initData = { groupKey: '', serverAddress: '', serverConfirmCode: '', serverSecretKey: '', tags: ''};
        if(this.props.item){
            initData = this.props.item
        }

        initData.serverAddress = BACKEND_URL + "/hook/vk/"+getRandomString(5)+"-"+(new Date()).getTime()
        initData.serverSecretKey = getRandomString(15)

        return (
            <Formik
                initialValues={initData}
                validate={values => {
                    const errors = {};
                    if (!values.groupKey) errors.groupKey = 'Required';
                    if (!values.serverAddress) errors.serverAddress = 'Required';
                    if (!values.serverConfirmCode) errors.serverConfirmCode = 'Required';
                    if (!values.serverSecretKey) errors.serverSecretKey = 'Required';
                    return errors;
                }}
                onSubmit={this.sendForm}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal title={'Добавление источника: Вконтакте'} setHook={this.props.setHook}  btn={{label: "Сохранить", handler: false}}>
                            <div className="w-full mb-6">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Ключ доступа
                                    </label>
                                    <input
                                        className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.groupKey}
                                        type="text" name={"groupKey"} placeholder="" />
                                    <p className="text-red-500 text-xs">{errors.groupKey && touched.groupKey && errors.groupKey}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Адрес сервера
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    readOnly={true}
                                    onBlur={handleBlur}
                                    value={values.serverAddress}
                                    type="text" name={"serverAddress"}  placeholder="" />
                                <p className="text-red-500 text-xs">{errors.serverAddress && touched.serverAddress && errors.serverAddress}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Код подтверждения сервера
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.serverConfirmCode}
                                    type="text" name={"serverConfirmCode"} placeholder=""  />
                                <p className="text-red-500 text-xs">{errors.serverConfirmCode && touched.serverConfirmCode && errors.serverConfirmCode}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Секретный ключ сервера
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    readOnly={true}
                                    value={values.serverSecretKey}
                                    type="text" name={"serverSecretKey"} placeholder=""  />
                                <p className="text-red-500 text-xs">{errors.serverSecretKey && touched.serverSecretKey && errors.serverSecretKey}</p>
                            </div>
                            <div className="w-full mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Теги к диалогам, через запятую
                                </label>
                                <input
                                    className="shadow appearance-none border mb-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tags}
                                    type="text" name={"tags"} placeholder=""  />
                                <p className="text-red-500 text-xs">{errors.tags && touched.tags && errors.tags}</p>
                            </div>
                        </Modal>
                    </form>
                )}
            </Formik>
        );
    }

}

export default ItemVk;